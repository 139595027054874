<template>
  <child-window2 ref="cw" class="window" :show="visible">
    <div class="header-top">
      <el-button type="primary" @click="create">新增地区限制</el-button>
      <el-button type="primary" @click="cancel">取消</el-button>
    </div>
    <div class="content">
      <DragTable
        :height="600"
        :header="header"
        :list-query="{ data: list }"
        style="margin-top: 20px"
      >
        <template #signup="{ row }">{{ ["可用", "不可用"][row.signup] }}</template>
        <template #signin="{ row }">{{ ["可用", "不可用"][row.signin] }}</template>
        <template #visit="{ row }">{{ ["可用", "不可用"][row.visit] }}</template>
        <template #ctl="{ row }">
          <span
            class="word-color"
            @click="edit(row)"
            style="margin-right: 5px"
          >编辑</span>
          <span
            class="word-color"
            @click="del(row)"
            style="margin-right: 5px"
          >删除</span>
        </template>
      </DragTable>
      <el-pagination
        style="text-align: right; margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageControl.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageControl.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageControl.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="`${dialogData.id ? '修改' : '新增'}地区访问限制`"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="dialog-row">
        <span class="inline-flex items-center">地区选择</span>
        <el-select v-model="dialogData.area_id" placeholder="请选择地区">
          <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <div class="dialog-row" v-if="lastArea">
        [ 当前地址：{{ lastArea }} ]
      </div>
      <div class="dialog-row">
        <span>支持操作</span>
        <div class="control-list">
          <div class="control-item">
            <span>地区注册</span>
            <el-switch :active-value="0" :inactive-value="1" v-model="dialogData.signup"></el-switch>
          </div>
          <div class="control-item">
            <span>地区登录</span>
            <el-switch :active-value="0" :inactive-value="1" v-model="dialogData.signin"></el-switch>
          </div>
          <div class="control-item">
            <span>地区访问</span>
            <el-switch :active-value="0" :inactive-value="1" v-model="dialogData.visit"></el-switch>
          </div>
          <div class="control-item">
            <span>搜索词</span>
            <el-switch :active-value="0" :inactive-value="1" v-model="dialogData.search_keyword"></el-switch>
          </div>
          <div class="control-item">
            <span>链接</span>
            <el-switch :active-value="0" :inactive-value="1" v-model="dialogData.search_url"></el-switch>
          </div>
        </div>
      </div>
      <div class="dialog-row">
        <span>备注</span>
        <el-input
          v-model="dialogData.remark"
          maxlength="200"
          placeholder="200个字符上限"
          type="textarea"
          rows="5"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit">确 定</el-button>
      </span>
    </el-dialog>
  </child-window2>
</template>
<script>
import {
  computed,
  defineComponent,
  ref,
  reactive,
  watch,
} from "@vue/composition-api";
import ChildWindow2 from "./ChildWindow.vue";

export default defineComponent({
  components: { ChildWindow2 },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  },
  setup(props, { root, emit }) {
    const cancel = () => {
      emit("update:show", false);
    };
    const create = () => {
      reset();
      dialogVisible.value = true;
    }
    const visible = computed(() => props.show);

    // 域名列表
    const header = [
      { name: "地区", value: "area_name" },
      { name: "注册限制", value: "signup", slot: "signup" },
      { name: "登录限制", value: "signin", slot: "signin" },
      { name: "访问限制", value: "visit", slot: "visit" },
      { name: "管理", slot: "ctl" }
    ];
    const list = ref([]);
    const areaList = ref([]);
    const areaRecords = {};
    // 获取地区列表
    const getAreaList = () => {
      if (areaList.value.length > 0) return;
      return root.$axios.get("/setup/restrict/area_list").then((res) => {
        if (res.data && Array.isArray(res.data)) {
          res.data.forEach((item) => {
            areaList.value.push({
              id: item.area_id,
              name: item.name
            });
            areaRecords[item.area_id] = item.name;
          })
        }
      });
    }
    // 获取地区限制列表
    const getList = async () => {
      root.$axios.get("/setup/restrict/list", {
        params: {
          size: pageControl.size,
          page: pageControl.page
        },
      }).then((res) => {
        list.value = res.data.data;
        pageControl.total = res.data.total;
      });
    };
    watch(
      () => props.show,
      (val) => {
        if (val) {
          getList();
        }
      }
    );
    getAreaList().then(() => getList());

    const dialogVisible = ref(false);
    const dialogData = reactive({
      id: "",
      area_id: "",
      visit: 0,
      signin: 0,
      signup: 0,
      search_keyword: 0,
      search_url: 0,
      remark: "",
    });
    const lastArea = ref("");
    const reset = () => {
      dialogData.id = "";
      dialogData.area_id = "";
      dialogData.visit = 0;
      dialogData.signin = 0;
      dialogData.signup = 0;
      dialogData.search_keyword = 0,
      dialogData.search_url = 0,
      dialogData.remark = "";
      lastArea.value = "";
    }
    // 显示配置编辑弹窗
    const edit = (data) => {
      if (data.id) {
        dialogData.id = data.id;
        dialogData.area_id = data.area_id;
        dialogData.signup = data.signup;
        dialogData.signin = data.signin;
        dialogData.visit = data.visit;
        dialogData.search_keyword = data.search_keyword,
        dialogData.search_url = data.search_url,
        dialogData.remark = data.remark;
        lastArea.value = `${areaRecords[data.area_id]}(${data.area_id})`
        dialogVisible.value = true;
      }
    }
    // 显示删除确认弹窗
    const del = (data) => {
      root.$goDialog('是否确认删除').then((confirmed) => {
        if(!confirmed) return
        const path = `/setup/restrict/delete/${data.id}`;
        root.$axios.delete(path).then((res) => {
          if (res.code !== 10000) return;
          root.$message.success("删除成功");
          getList();
        });
      });
    };
    // 确认添加或修改配置
    const confirmEdit = () => {
      root.$axios.post("/setup/restrict/save", {
        ...dialogData,
        area_name: areaRecords[dialogData.area_id]
      }).then((res) => {
        if (res.code !== 10000) return;
        const msg = dialogData.id ? "修改成功" : "创建成功"
        root.$message.success(msg);
        getList();  
        dialogVisible.value = false;
      })
    }

    // 分页
    const pageControl = reactive({
      page: 1,
      size: 10,
      total: 0,
    });
    const handleSizeChange = (val) => {
      pageControl.size = val;
      pageControl.page = 1;
      getList();
    };
    const handleCurrentChange = (val) => {
      pageControl.page = val;
      getList();
    };

    return {
      cancel,
      create,
      visible,
      list,
      header,
      edit,
      del,
      confirmEdit,
      dialogVisible,
      dialogData,
      areaList,
      lastArea,
      pageControl,
      handleSizeChange,
      handleCurrentChange
    };
  },
});
</script>
<style lang="scss" scoped>
.header-top {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.window {
  padding: 20px 40px;
}

.dialog-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > span {
    flex-shrink: 0;
    min-width: 100px;
    line-height: 40px;
  }
  & + .dialog-row {
    margin-top: 20px;
  }
  .control-list {
    line-height: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -20px;
  }
  .control-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;
    & > span {
      margin-right: 12px;
    }
  }
}
</style>