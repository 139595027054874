<template>
  <div>
    <div class="header">
      <div></div>
    </div>
    <DragTable
      :header="header"
      :list-query="list"
    >
      <template #switchStatus="{ row }">{{
        row.switchStatus ? "开" : "关"
      }}</template>
      <template #control="{ row }">
        <div class="control">
          <span @click="edit(row)">编辑</span>
        </div>
      </template>
    </DragTable>
    <go-dialog v-model="show">
      <h1 class="title">{{ categoryDetail.siteName }}</h1>
      <div>
        <template v-if="categoryDetail.parsingServiceList && categoryDetail.parsingServiceList.length>0">
          <h3>解析服务</h3>
          <el-radio
          class="box-radio"
            v-for="item in categoryDetail.parsingServiceList"
            :label="item.id"
            :key="item.id"
            v-model="categoryDetail.parsingServiceId"
          >{{item.name}}</el-radio>
        </template>
        <h3>解析开关</h3>
        <el-radio class="box-radio" v-model="categoryDetail.switchStatus" :label="1">开</el-radio>
        <el-radio class="box-radio" v-model="categoryDetail.switchStatus" :label="0">关</el-radio>
      </div>
      <footer class="box-footer">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  setup(props, { root }) {
    const categoryDetail = ref({});
    const show = ref(false);
    const header = [
      { name: "站点", value: "siteName" },
      { name: "域名", value: "originalHost" },
      { name: "解析服务", value: "parsingServiceName" },
      { name: "解析开关", slot: "switchStatus" },
      { name: "操作", slot: "control", width: 80 },
    ];
    const list = reactive({ data: [] });
    watch(show, (val) => {
      if (!val) {
        getList();
      }
    });
    const getList = () => {
      root.$axios
        .get("/site/regulates/list")
        .then((res) => {
          list.data = res.data.result;
        })
        
    };
    getList();
    const edit = (data) => {
      root.$axios
        .get("/site/regulates/show", {
          params: { id: data.id },
        })
        .then((res) => {
          categoryDetail.value = res.data;
          show.value = true;
        })
        
    };
    const save = () => {
      // if(!categoryDetail.value.parsingServiceId) {
      //   root.$message.warning('请选择解析服务')
      //   return
      // }
      root.$axios.post(`/site/regulates/update`, {
        id: categoryDetail.value.id,
        switchStatus: categoryDetail.value.switchStatus,
        parsingServiceId: categoryDetail.value.parsingServiceId
      })
      .then(res=>{
        root.$message.success(res.msg)
        cancel()
      })
      
    };
    const cancel = ()=>{
      for(let key in categoryDetail.value) {
        categoryDetail.value[key] = ''
      }
      show.value = false;
    }
    return {
      header,
      list,
      categoryDetail,
      show,
      edit,
      getList,
      save,
      cancel
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.control {
  display: flex;
  justify-content: center;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.site-img {
  width: 100%;
  max-height: 120px;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h1{
  margin-bottom: 20px;
}
h3{
  margin-bottom: 15px;
  margin-top: 10px;
}
.box-radio{
  margin-right: 15px;
}
.box-footer{
  margin-top: 20px;
}
</style>